






import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

@Component
export default class CheckboxGroupRequired extends Vue {
  @Prop({ type: String, default: 'At least one checkbox must be selected' }) errorMessage!: string;

  inputs: HTMLInputElement[] = [];

  checkValidity() {
    if (this.inputs.length === 0) return;
    const firstInput = this.inputs[0];
    const isChecked = this.inputs.some((input) => input.checked);
    const message = isChecked ? '' : this.errorMessage;
    firstInput.setCustomValidity(message);
  }

  mounted() {
    const { container } = this.$refs;
    if (container instanceof Element) {
      const inputs = container.querySelectorAll('input[type="checkbox"]') as NodeListOf<HTMLInputElement>;
      this.inputs = [...inputs];
      this.inputs.forEach((input) => {
        // eslint-disable-next-line no-param-reassign
        input.onchange = this.checkValidity;
      });
      this.checkValidity();
    }
  }

  beforeDestroy() {
    this.inputs.forEach((input) => {
      // eslint-disable-next-line no-param-reassign
      input.onchange = null;
    });
  }
}
