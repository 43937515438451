





































































































































































import {
  AllowData, AllowRequestPayload, IdentifierCheck, MessageKeys, NameCheck, Pages,
} from '@/types';
import { BaseComponent, Component } from '@/components/BaseComponent';
import CheckboxGroupRequired from '@/components/CheckboxGroupRequired.vue';

@Component({
  components: {
    CheckboxGroupRequired,
  },
})
export default class Allow extends BaseComponent {
  checks: AllowData | null = null;

  permissions: AllowRequestPayload = {
    identifier: [],
    name: [],
    communication: [],
  };

  get settings() {
    return this.store.getSettings();
  }

  // eslint-disable-next-line class-methods-use-this
  getFullName(name: NameCheck) {
    return `${name.last_name || ''} ${name.first_name} ${name.middle_name || ''}`.trim();
  }

  // eslint-disable-next-line class-methods-use-this
  getIdentifier(identifierCheck: IdentifierCheck) {
    const { identifier } = identifierCheck;
    return `${identifierCheck.type}: ${identifier}`;
  }

  getErrorMessage(item: MessageKeys, type: string) {
    return this.i18n.translate('atLeastOneWithTypeIsRequired', [this.i18n.translate(item), type]);
  }

  async sendPermissions() {
    const result = await this.pds.allow(this.permissions);
    this.router.changePage(result);
  }

  setDefaultPermissions() {
    if (this.checks == null) return;
    this.setOptionalChecks('communication', this.checks.optional_communication_checks);
    this.setOptionalChecks('identifier', this.checks.optional_identifier_checks);
    this.setOptionalChecks('name', this.checks.optional_name_checks);
    this.setForceChecks('communication', this.checks.force_communication_checks);
    this.setForceChecks('identifier', this.checks.force_identifier_checks);
    this.setForceChecks('name', this.checks.force_name_checks);
  }

  setOptionalChecks(
    name: keyof AllowRequestPayload,
    checks: {check: boolean; index: number}[] = [],
  ) {
    checks.forEach((item) => {
      if (item.check) {
        this.permissions[name].push(item.index);
      }
    });
  }

  setForceChecks(
    name: keyof AllowRequestPayload,
    checks: {[type: string]: {
      check: boolean;
      index: number;
    }[];} = {},
  ) {
    Object.keys(checks).forEach((type) => {
      checks[type].forEach((item) => {
        if (item.check) {
          this.permissions[name].push(item.index);
        }
      });
    });
  }

  created() {
    const page = this.store.getPage(Pages.Allow);
    if (page) {
      this.checks = page;
      this.setDefaultPermissions();
    } else {
      this.router.goTo(Pages.Login);
    }
  }
}
